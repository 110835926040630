import {useNavigate} from "react-router-dom";
import {Col, Container, Row, Form, Button, Spinner} from "react-bootstrap";
import React from "react";
import './Enroll.scss';
import {useFormik} from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {useIntl} from "react-intl";
import moment from "moment";
const MySwal = withReactContent(Swal)

const Enroll = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  // const endpoint = 'http://127.0.0.1:5001/rubypay-my/us-central1/sendMail';
  const endpoint = 'https://sendmail-akdf45bd5q-uc.a.run.app';

  const questions = [
    {
      id: 'serviceType',
      question: intl.formatMessage({id: "enroll.q1.1"}),
      answers: [
        intl.formatMessage({id: "enroll.q1.1.1"}),
        intl.formatMessage({id: "enroll.q1.1.2"}),
        intl.formatMessage({id: "enroll.q1.1.3"}),
        intl.formatMessage({id: "enroll.q1.1.4"}),
      ]
    },
    {
      id: 'paymentUsage',
      question: intl.formatMessage({id: "enroll.q1.2"}),
      answers: [
        intl.formatMessage({id: "enroll.q1.2.1"}),
        intl.formatMessage({id: "enroll.q1.2.2"}),
      ],
      single: true,
    },
    {
      id: 'targetMarket',
      question: intl.formatMessage({id: "enroll.q1.3"}),
      answers: [
        intl.formatMessage({id: "malaysia"}),
        intl.formatMessage({id: "thailand"}),
        intl.formatMessage({id: "china"}),
        intl.formatMessage({id: "singapore"}),
        intl.formatMessage({id: "indonesia"}),
        intl.formatMessage({id: "vietnam"}),
      ]
    },
    {
      id: 'processingCurrency',
      question: intl.formatMessage({id: "enroll.q1.4"}),
      answers: [
        intl.formatMessage({id: "myr"}),
        intl.formatMessage({id: "idr"}),
        intl.formatMessage({id: "sgd"}),
        intl.formatMessage({id: "thb"}),
        intl.formatMessage({id: "usd"}),
        intl.formatMessage({id: "vnd"}),
        intl.formatMessage({id: "cny"}),
      ]
    },
    {
      id: 'settlementCurrency',
      question: intl.formatMessage({id: "enroll.q1.5"}),
      answers: [
        intl.formatMessage({id: "myr"}),
        intl.formatMessage({id: "idr"}),
        intl.formatMessage({id: "sgd"}),
        intl.formatMessage({id: "thb"}),
        intl.formatMessage({id: "usd"}),
        intl.formatMessage({id: "vnd"}),
        intl.formatMessage({id: "cny"}),
      ]
    },
    {
      id: 'monthlyTransNo',
      question: intl.formatMessage({id: "enroll.q1.6"}),
      answers: [
        intl.formatMessage({id: "unknown"}),
        '100 - 999',
        intl.formatMessage({id: "lessThan10"}),
        '1000 - 9999',
        '10 - 99',
        '10 000+',
      ],
      single: true,
    },
    {
      id: 'avgTransValue',
      question: intl.formatMessage({id: "avgTrans"}),
      answers: [
        intl.formatMessage({id: "unknown"}),
        '10 000+',
        intl.formatMessage({id: "lessThan100"}),
        '100 - 999',
        '10 - 99',
        '1000 - 9999',
        '100 - 999',
        '10 000+',
        '1000 - 9999',
      ],
      single: true,
    },
  ];

  const formik = useFormik({
    initialValues: {
      companyName: '',
      websiteUrl: '',
    },
    onSubmit: (values) => {
      const answered = Object.keys(values);
      const submitData = {one: {}, two: {...values, dateTime: moment().format("ddd, MMM d, YYYY h:mm A (Z)")}};

      let canSubmit = true;

      for (let x = 0; x < questions.length; x += 1) {
        const q = questions[x];
        let found = false;
        const customerAns = {};
        customerAns[`${q.id}`] = [];

        for (let y = 0; y < answered.length; y += 1) {
          const ak = answered[y];

          if (ak.includes(q.id) && values[ak].length > 0) {
            const idx = ak.split('_')[1] * 1;
            customerAns[`${q.id}`].push(q.answers[idx]);
            found = true;
          }
        }

        if (found) {
          customerAns[`${q.id}`] = customerAns[`${q.id}`].join(', ');
          submitData.one[`${q.id}`] = customerAns[`${q.id}`];
          document.getElementById(`error__${q.id}`).style.display = 'none';
        } else {
          canSubmit = false;
          document.getElementById(q.id).scrollIntoView();
          document.getElementById(`error__${q.id}`).style.display = 'block';
        }
      }

      if (canSubmit && window.grecaptcha) {
        MySwal.fire({
          html: <Container className="pt-4"><Spinner variant="primary"/><p className="pt-4">{intl.formatMessage({id: "submitting"})}</p></Container>,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        }).then();
        window.grecaptcha.execute('6LfZjOsoAAAAALBSRXcCUXBZrc2IM83jb9iZQ63_', {action: 'submit'}).then((token) => {
          fetch(endpoint, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...submitData,
              token,
            }),
          })
            .then(() => {
              MySwal.fire(
                {
                  title: intl.formatMessage({id: "success"}),
                  text: intl.formatMessage({id: "submitSuccess"}),
                  icon: 'success',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }
              ).then(() => {
                setTimeout(() => navigate("/"), 100);
              });
            })
            .catch(() => {
              MySwal.fire(
                intl.formatMessage({id: "error"}),
                intl.formatMessage({id: "submitError"}),
                'error'
              ).then();
            })
            .finally(() => {
              MySwal.hideLoading();
            });
        });
      }
    },
  });

  const {setFieldValue, values} = formik;

  return (
    <Container className="enroll__wrapper">
      <form onSubmit={formik.handleSubmit}>
      <h4>{intl.formatMessage({id: "header.enroll"})}</h4>
      <h5>1. {intl.formatMessage({id: "enroll.q1"})}</h5>
      <Row>
        <Col md={6}>
          {
            questions.map((q) => (
              <div className="enroll__questionWrapper" id={q.id}>
                <h6>{q.question}</h6>
                <p id={`error__${q.id}`}>{intl.formatMessage({id: "pleaseChooseOne"})}</p>
                <Row>
                  {
                    q.answers.map((a, ai) => (
                      <Col className="mt-3" md={6}>
                        <Form.Check
                          onChange={(e) => {
                            document.getElementById(`error__${q.id}`).style.display = 'none';
                            if (q.single) {
                              q.answers.forEach((a, i) => setFieldValue(`${q.id}_${i}`, []));
                            }
                            formik.handleChange(e);
                          }}
                          type="checkbox"
                          id={`${q.id}_${ai}`}
                          label={a}
                          checked={values[`${q.id}_${ai}`]?.length > 0}
                        />
                      </Col>
                    ))
                  }
                </Row>
              </div>
            ))
          }
        </Col>
        <Col md={6}/>
      </Row>
      <h5>2. {intl.formatMessage({id: "enroll.q2"})}</h5>
        <Row>
          <Col md={4}>
            <Form.Label htmlFor="name">{intl.formatMessage({id: "enroll.q2.1"})}</Form.Label>
            <Form.Control
              required
              onChange={formik.handleChange}
              type="text"
              id="name"
            />
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="phoneNumber">{intl.formatMessage({id: "enroll.q2.2"})}</Form.Label>
            <Form.Control
              required
              onChange={formik.handleChange}
              type="text"
              id="phoneNumber"
            />
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="email">{intl.formatMessage({id: "enroll.q2.3"})}</Form.Label>
            <Form.Control
              required
              onChange={formik.handleChange}
              type="email"
              id="email"
            />
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="companyName">{intl.formatMessage({id: "enroll.q2.4"})}</Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              type="text"
              id="companyName"
            />
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="websiteUrl">{intl.formatMessage({id: "enroll.q2.5"})}</Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              type="url"
              id="websiteUrl"
            />
          </Col>
          <Col md={12}>
            <Form.Label htmlFor="message">{intl.formatMessage({id: "enroll.q2.6"})}</Form.Label>
            <textarea required className="form-control" rows="3" id="message" onChange={formik.handleChange}></textarea>
          </Col>
        </Row>
        <Button type="submit">{intl.formatMessage({id: "submit"})}</Button>
      </form>
    </Container>
  )
}

export default Enroll;

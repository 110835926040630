import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import Home from "./Home";
import Enroll from "./Enroll";

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children: [
      {
        path: '/',
        element: <Home/>,
      },
      {
        path: '/enroll',
        element: <Enroll/>,
      }
    ]
  }
]);

export default router;
import React from "react";

const LangContext = React.createContext([]);
export const locales = [
  {value: 'en', label: 'EN'},
  {value: 'cn', label: '中文'},
  // {value: 'ms', label: 'MS'},
]

export default LangContext;

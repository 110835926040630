import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {IntlProvider} from "react-intl";
import {RouterProvider} from "react-router-dom";
import router from "./router";
import English from './lang/en.json';
import Malaysian from './lang/my.json';
import Chinese from './lang/cn.json';
import LangContext from "./lang/context";

const Main = () => {
  const [locale, setLocale] = useState('en');
  const [messages, setMessages] = useState(English);

  const selectLanguage = (l) => {
    switch (l) {
      case "ms":
        setLocale('ms');
        setMessages(Malaysian);
        break;
      case "zh":
      case "cn":
        setLocale('cn');
        setMessages(Chinese);
        break;
      default:
        setLocale('en');
        setMessages(English);
        break;
    }
  }

  useEffect(() => {
    selectLanguage(navigator.language);
  }, []);

  return (
    <LangContext.Provider value={[locale, selectLanguage]}>
      <IntlProvider messages={messages} locale={locale}>
        <RouterProvider router={router} />
      </IntlProvider>
    </LangContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main/>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import WHYCH_1 from "./assets/whych_1.png";
import WHYCH_2 from "./assets/whych_2.png";
import WHYCH_3 from "./assets/whych_3.png";
import WHYCH_4 from "./assets/whych_4.png";
import WHYCH_5 from "./assets/whych_5.png";
import WHYCH_6 from "./assets/whych_6.png";
import WHYCH_7 from "./assets/whych_7.png";
import WHYCH_8 from "./assets/whych_8.png";
import WHYCH_9 from "./assets/whych_9.png";

import ICO_CREDITCARD from "./assets/ico_creditcard.png";
import ICO_ONLINEBANKING from "./assets/ico_onlinebanking.png";
import ICO_EWALLET from "./assets/ico_ewallet.png";
import ICO_ARROW from "./assets/ico_arrow.svg";

import FLAG_CC from "./assets/flag_cc.png";
import FLAG_CN from "./assets/flag_cn.png";
import FLAG_ID from "./assets/flag_id.png";
import FLAG_MY from "./assets/flag_my.png";
import FLAG_PH from "./assets/flag_ph.png";
import FLAG_SG from "./assets/flag_sg.png";
import FLAG_TH from "./assets/flag_th.png";
import FLAG_VN from "./assets/flag_vn.png";
//CC
import CC_B1 from './assets/cc_banks/Rectangle 277.png';
import CC_B2 from './assets/cc_banks/Rectangle 283.png';
import CC_B3 from './assets/cc_banks/Rectangle 286.png';
import CC_B4 from './assets/cc_banks/Rectangle 291.png';
//CN
import CN_W1 from './assets/cn_wallets/Rectangle 246.png';
import CN_W2 from './assets/cn_wallets/Rectangle 252.png';
//ID
import ID_B1 from './assets/id_banks/Rectangle 217.png';
import ID_B2 from './assets/id_banks/Rectangle 222.png';
import ID_B3 from './assets/id_banks/Rectangle 225.png';
import ID_B4 from './assets/id_banks/Rectangle 228.png';
import ID_W1 from './assets/id_wallets/Rectangle 218.png';
//MY
import MY_B1 from './assets/my_banks/Rectangle 166.png';
import MY_B2 from './assets/my_banks/Rectangle 167.png';
import MY_B3 from './assets/my_banks/Rectangle 168.png';
import MY_B4 from './assets/my_banks/Rectangle 169.png';
import MY_B5 from './assets/my_banks/Rectangle 170.png';
import MY_B14 from './assets/my_banks/Rectangle 171.png';
import MY_B6 from './assets/my_banks/Rectangle 190.png';
import MY_B7 from './assets/my_banks/Rectangle 191.png';
import MY_B8 from './assets/my_banks/Rectangle 192.png';
import MY_B9 from './assets/my_banks/Rectangle 193.png';
import MY_B10 from './assets/my_banks/Rectangle 194.png';
import MY_B12 from './assets/my_banks/Rectangle 195.png';
import MY_B13 from './assets/my_banks/Rectangle 196.png';
import MY_B15 from './assets/my_banks/Rectangle 198.png';
import MY_B16 from './assets/my_banks/Rectangle 200.png';
import MY_B17 from './assets/my_banks/Rectangle 202.png';
import MY_B18 from './assets/my_banks/Rectangle 204.png';
import MY_B11 from './assets/my_banks/Rectangle 206.png';

import MY_W1 from './assets/my_wallets/Rectangle 207.png';
import MY_W2 from './assets/my_wallets/Rectangle 208.png';
import MY_W3 from './assets/my_wallets/Rectangle 209.png';
import MY_W4 from './assets/my_wallets/Rectangle 210.png';
import MY_W5 from './assets/my_wallets/Rectangle 211.png';
import MY_W6 from './assets/my_wallets/Rectangle 212.png';
import MY_W7 from './assets/my_wallets/Rectangle 213.png';
//PH
import PH_B1 from './assets/ph_banks/Rectangle 258.png';
import PH_B2 from './assets/ph_banks/Rectangle 262.png';
import PH_B3 from './assets/ph_banks/Rectangle 265.png';
import PH_B4 from './assets/ph_banks/Rectangle 268.png';
import PH_B5 from './assets/ph_banks/Rectangle 270.png';
import PH_B6 from './assets/ph_banks/Rectangle 272.png';
import PH_B7 from './assets/ph_banks/Rectangle 273.png';
import PH_B8 from './assets/ph_banks/Rectangle 274.png';
import PH_W1 from './assets/ph_wallets/Rectangle 259.png';
//SG
import SG_B1 from './assets/sg_banks/Rectangle 166.png';
import SG_B2 from './assets/sg_banks/Rectangle 168.png';
import SG_B3 from './assets/sg_banks/Rectangle 169.png';
import SG_B4 from './assets/sg_banks/Rectangle 171.png';
//TH
import TH_B1 from './assets/th_banks/Rectangle 166.png';
import TH_B2 from './assets/th_banks/Rectangle 167.png';
import TH_B3 from './assets/th_banks/Rectangle 168.png';
import TH_B4 from './assets/th_banks/Rectangle 169.png';
import TH_B5 from './assets/th_banks/Rectangle 171.png';
import TH_W1 from './assets/th_wallets/Rectangle 207.png';
//VN
import VN_W1 from './assets/vn_wallets/Rectangle 232.png';
import VN_W2 from './assets/vn_wallets/Rectangle 236.png';
import VN_W3 from './assets/vn_wallets/Rectangle 239.png';

import HDIS_1 from './assets/hdis_1.png';
import HDIS_2 from './assets/hdis_2.png';
import HDIS_3 from './assets/hdis_3.png';
import {useEffect, useRef, useState} from "react";
import {Accordion, Button, Col, Container, Dropdown, Row, useAccordionButton} from "react-bootstrap";
import BANNER_IMG from "./assets/banner1.png";
import BANNER_IMG_2 from "./assets/banner2.png";
import IMG_ABOUT_1 from "./assets/img_about_1.svg";
import IMG_ABOUT_2 from "./assets/img_about_2.svg";
import IMG_ABOUT_3 from "./assets/img_about_3.svg";
import IMG_ABOUT_4 from "./assets/img_about_4.svg";
import IMG_ABOUT_5 from "./assets/img_about_5.svg";
import {useNavigate} from "react-router-dom";
import BANNER_BG from "./assets/banner_bg.png";
import Slider from "react-slick";
import {useIntl} from "react-intl";

const Home = () => {
  const navigate = useNavigate();
  const [activeCountry, setActiveCountry] = useState('0');
  const [isWhyExpanded, setIsWhyExpanded] = useState(false);
  const carouselRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    carouselRef.current?.element?.addEventListener("dragend", (e) => {
      console.log("drag");
    });
  }, []);

  const whyChooseUsItems1 = [
    {
      icon: WHYCH_1,
      title: intl.formatMessage({id: "section2.whyChoose1header"}),
      description: intl.formatMessage({id: "section2.whyChoose1content"}),
    },
    {
      icon: WHYCH_2,
      title: intl.formatMessage({id: "section2.whyChoose2header"}),
      description: intl.formatMessage({id: "section2.whyChoose2content"}),
    },
    {
      icon: WHYCH_3,
      title: intl.formatMessage({id: "section2.whyChoose3header"}),
      description: intl.formatMessage({id: "section2.whyChoose3content"}),
    },
  ];

  const whyChooseUsItems2 = [
    ...whyChooseUsItems1,
    {
      icon: WHYCH_4,
      title: intl.formatMessage({id: "section2.whyChoose4header"}),
      description: intl.formatMessage({id: "section2.whyChoose4content"}),
    },
    {
      icon: WHYCH_5,
      title: intl.formatMessage({id: "section2.whyChoose5header"}),
      description: intl.formatMessage({id: "section2.whyChoose5content"}),
    },
    {
      icon: WHYCH_6,
      title: intl.formatMessage({id: "section2.whyChoose6header"}),
      description: intl.formatMessage({id: "section2.whyChoose6content"}),
    },
    {
      icon: WHYCH_7,
      title: intl.formatMessage({id: "section2.whyChoose7header"}),
      description: intl.formatMessage({id: "section2.whyChoose7content"}),
    },
    {
      icon: WHYCH_8,
      title: intl.formatMessage({id: "section2.whyChoose8header"}),
      description: intl.formatMessage({id: "section2.whyChoose8content"}),
    },
    {
      icon: WHYCH_9,
      title: intl.formatMessage({id: "section2.whyChoose9header"}),
      description: intl.formatMessage({id: "section2.whyChoose9content"}),
    },
  ];

  const countries = [
    {
      id: '0',
      flag: FLAG_MY,
      title: intl.formatMessage({id: "malaysia"}),
      banking: [MY_B1,MY_B2,MY_B3,MY_B4,MY_B5,MY_B6,MY_B7,MY_B8,MY_B9,MY_B10,MY_B11,MY_B12,MY_B13,MY_B14,MY_B15,MY_B16,MY_B17,MY_B18],
      wallets: [MY_W1,MY_W2,MY_W3,MY_W4,MY_W5,MY_W6,MY_W7],
    },
    {
      id: '1',
      flag: FLAG_SG,
      title: intl.formatMessage({id: "singapore"}),
      banking: [SG_B1,SG_B2,SG_B3,SG_B4],
      wallets: [],
    },
    {
      id: '2',
      flag: FLAG_TH,
      title: intl.formatMessage({id: "thailand"}),
      banking: [TH_B1,TH_B2,TH_B3,TH_B4,TH_B5],
      wallets: [TH_W1],
    },
    {
      id: '3',
      flag: FLAG_ID,
      title: intl.formatMessage({id: "indonesia"}),
      banking: [ID_B1,ID_B2,ID_B3,ID_B4],
      wallets: [ID_W1],
    },
    {
      id: '4',
      flag: FLAG_VN,
      title: intl.formatMessage({id: "vietnam"}),
      banking: [],
      wallets: [VN_W1,VN_W2,VN_W3],
    },
    {
      id: '5',
      flag: FLAG_CN,
      title: intl.formatMessage({id: "china"}),
      banking: [],
      wallets: [CN_W1,CN_W2],
    },
    {
      id: '6',
      flag: FLAG_PH,
      title: intl.formatMessage({id: "philippines"}),
      banking: [PH_B1,PH_B2,PH_B3,PH_B4,PH_B5,PH_B6,PH_B7,PH_B8],
      wallets: [PH_W1],
    },
    {
      id: '7',
      flag: FLAG_CC,
      title: intl.formatMessage({id: "cryptoCurrency"}),
      banking: [CC_B1,CC_B2,CC_B3,CC_B4],
      wallets: [],
    },
  ];

  function PaymentChannel({ country }) {
    const decoratedOnClick = useAccordionButton(country.id, () => {
      setActiveCountry(country.id);
    });

    return (
      <Col lg={2} md={3} className="p-2">
        <div
          className={`section3__countryFlagWrapper ${activeCountry === country.id ? 'active' : ''}`}
          onClick={decoratedOnClick}
        >
          <img src={country.flag} alt="" />
          <span>{country.title}</span>
        </div>
      </Col>
    );
  }

  function PaymentChannelMobile({ country }) {
    const decoratedOnClick = useAccordionButton(country.id, () => {
      setActiveCountry(country.id);
    });

    return (
      <>
        <Dropdown.Item className="section3__countryDropdownItem" onClick={decoratedOnClick}>
          <img src={country.flag} alt="" />
          {country.title}
        </Dropdown.Item>
      </>
    );
  }

  const buildCountryDropdown = (con) => {
    return (
      <Dropdown.Toggle className="section3__countryDropdownButton" id="country-dropdown">
        <img src={con.flag} alt="" />
        {con.title}
      </Dropdown.Toggle>
    )
  }

  return (
    <>
      <div className="banner__wrapper" style={{backgroundImage: `url(${BANNER_BG})`}}>
        <Slider arrows={false} centerMode centerPadding="0px" dots autoplay autoplaySpeed={3000} pauseOnHover={false} useTransform={false}>
          <div>
            <Container>
              <Row className="align-items-center m-4">
                <Col md={6} className="banner__text">
                  <h3>{intl.formatMessage({id: "banner.slide1header"})}</h3>
                  <p>{intl.formatMessage({id: "banner.slide1desc"})}</p>
                  <button className="btn btn-primary" onClick={() => navigate("/enroll")}>{intl.formatMessage({id: "banner.learnMore"})}</button>
                </Col>
                <Col md={6} className="banner__image p-5" style={{backgroundImage: `url(${BANNER_IMG})`}} />
              </Row>
            </Container>
          </div>
          <div>
            <Container>
              <Row className="align-items-center m-4">
                <Col md={6} className="banner__text">
                  <h3>{intl.formatMessage({id: "banner.slide2header"})}</h3>
                  <p>{intl.formatMessage({id: "banner.slide2desc"})}</p>
                  <button className="btn btn-primary" onClick={() => navigate("/enroll")}>{intl.formatMessage({id: "banner.learnMore"})}</button>
                </Col>
                <Col md={6} className="banner__image p-5" style={{backgroundImage: `url(${BANNER_IMG_2})`}} />
              </Row>
            </Container>
          </div>
        </Slider>
      </div>

      <Container className="section1" id="app__aboutus">
        <h3>{intl.formatMessage({id: "section1.aboutUs"})}</h3>
        <p>{intl.formatMessage({id: "section1.aboutUsContent"})}</p>
        <h4>{intl.formatMessage({id: "section1.makeThrive"})}</h4>
        <Row className="section1__itemList__wrapper">
          <Col md={2}>
            <div className="section1__item__wrapper">
              <img src={IMG_ABOUT_1} alt="" width={77}/>
              <span>{intl.formatMessage({id: "section1.makeThrive1"})}</span>
            </div>
          </Col>
          <Col md={2}>
            <div className="section1__item__wrapper">
              <img src={IMG_ABOUT_2} alt="" width={77}/>
              <span>{intl.formatMessage({id: "section1.makeThrive2"})}</span>
            </div>
          </Col>
          <Col md={2}>
            <div className="section1__item__wrapper">
              <img src={IMG_ABOUT_3} alt="" width={77}/>
              <span>{intl.formatMessage({id: "section1.makeThrive3"})}</span>
            </div>
          </Col>
          <Col md={2}>
            <div className="section1__item__wrapper">
              <img src={IMG_ABOUT_4} alt="" width={77}/>
              <span>{intl.formatMessage({id: "section1.makeThrive4"})}</span>
            </div>
          </Col>
          <Col md={2}>
            <div className="section1__item__wrapper">
              <img src={IMG_ABOUT_5} alt="" width={77}/>
              <span>{intl.formatMessage({id: "section1.makeThrive5"})}</span>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="section2" id="app__wcu">
        <h3>{intl.formatMessage({id: "section2.whyChoose"})}</h3>
        <Row>
          {(isWhyExpanded ? whyChooseUsItems2 : whyChooseUsItems1).map((item) => (
            <Col md={4} sm={6} className="section2__item__wrapper">
              <img src={item.icon} alt={item.title}/>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </Col>
          ))}
        </Row>
        <Button
          className="section2__moreBtn"
          onClick={() => setIsWhyExpanded((c) => !c)}>
          {isWhyExpanded ? intl.formatMessage({id: "less"}) : intl.formatMessage({id: "more"})} <img src={ICO_ARROW} alt="" style={{rotate: isWhyExpanded ? '0deg' : '180deg'}} />
        </Button>
      </Container>

      <div className="section3" id="app__services">
        <Container>
          <h3>{intl.formatMessage({id: "section3.products"})}</h3>
          <Row>
            <Col md={4} sm={6} className="section3__productwrapper">
              <img src={ICO_CREDITCARD} alt="" />
              <h4>{intl.formatMessage({id: "section3.product1"})}</h4>
            </Col>
            <Col md={4} sm={6} className="section3__productwrapper">
              <img src={ICO_ONLINEBANKING} alt="" />
              <h4>{intl.formatMessage({id: "section3.product2"})}</h4>
            </Col>
            <Col md={4} sm={6} className="section3__productwrapper">
              <img src={ICO_EWALLET} alt="" />
              <h4>{intl.formatMessage({id: "section3.product3"})}</h4>
            </Col>
          </Row>
          <h4>{intl.formatMessage({id: "section3.paymentChannels"})}</h4>
          <p>{intl.formatMessage({id: "section3.paymentChannelsDesc"})}</p>
          <Accordion defaultActiveKey="0">
            <Row className="d-md-flex d-none justify-content-center">
              {countries.map((country) => <PaymentChannel country={country}/>)}
            </Row>
            <div className="d-block d-md-none">
              <Dropdown className="section3__countryDropdown">
                {buildCountryDropdown(countries.filter((c) => c.id === activeCountry)[0])}

                <Dropdown.Menu>
                  {countries.map((country) => <PaymentChannelMobile country={country}/>)}
                </Dropdown.Menu>
              </Dropdown>

            </div>
            {
              countries.map((country) => (
                <Accordion.Collapse eventKey={country.id} className="section3__countryPayment">
                  <div>
                    {
                      country.banking.length > 0 &&
                      <>
                        <h4>{intl.formatMessage({id: "section3.product2"})}</h4>
                        <Row className="p3">
                          {country.banking.map((b) => <Col lg={2} md={4} sm={12} className="p-2"><img src={b} alt="" /></Col>)}
                        </Row>
                      </>
                    }
                    {
                      country.wallets.length > 0 &&
                      <>
                        <h4>{intl.formatMessage({id: "section3.product3"})}</h4>
                        <Row className="p-3">
                          {country.wallets.map((w) => <Col lg={2} md={4} sm={12} className="p-2"><img src={w} alt="" /></Col>)}
                        </Row>
                      </>
                    }
                  </div>
                </Accordion.Collapse>
              ))
            }
          </Accordion>
        </Container>
      </div>

      <div className="section4"  id="app__hdis">
        <Container>
          <h3>{intl.formatMessage({id: "section4.howDoIStart"})}</h3>
          <Row>
            <Col md={4} sm={6} className="section3__productwrapper">
              <img src={HDIS_1} alt="" />
              <h4>{intl.formatMessage({id: "section4.contactUs"})}</h4>
            </Col>
            <Col md={4} sm={6} className="section3__productwrapper">
              <img src={HDIS_2} alt="" />
              <h4>{intl.formatMessage({id: "section4.integrate"})}</h4>
            </Col>
            <Col md={4} sm={6} className="section3__productwrapper">
              <img src={HDIS_3} alt="" />
              <h4>{intl.formatMessage({id: "section4.commence"})}</h4>
            </Col>
          </Row>

          <Button onClick={() => navigate('/enroll')}>{intl.formatMessage({id: "header.enroll"})}</Button>
        </Container>
      </div>
    </>
  )
}

export default Home;

import './App.scss';
import {Accordion, Button, Col, Container, Dropdown, Row, useAccordionButton} from "react-bootstrap";
import RUBY_PAY from "./assets/ruby_logo.png";
import ICO_LOCATION from "./assets/ico_location.png";
import ICO_MAIL from "./assets/ico_mail.png";
import ICO_MENU from "./assets/ico_menu.svg";
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import LangContext, {locales} from "./lang/context";


const App = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const intl = useIntl();
  const [locale, setLocale] = useContext(LangContext);
  const [localeLabel, setLocaleLabel] = useState('EN');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const closeMobileMenu = useCallback(() => {
    if (isMobileMenuOpen) {
      document.getElementById("app__mobileMenu")?.click();
      setIsMobileMenuOpen(false);
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    setTimeout(() => {
      toTop();
    }, 100);
  }, [pathname]);

  const toTop = () => {
    document.getElementById('app__header').scrollIntoView();
  }

  const handleHomeSectionClick = (sectionId) => {
    navigate('/');
    setTimeout(() => {
      document.getElementById(sectionId).scrollIntoView();
    }, 100);
  }

  const handleContactClick = () => {
    setTimeout(() => {
      document.getElementById('app__footer').scrollIntoView();
    }, 100);
  }

  const MobileMenuBtn = () => {
    const mobileMenuClick = useAccordionButton("0", () => {});

    return (
      <Button variant="secondary" className="d-block d-lg-none app__menuBtn" onClick={mobileMenuClick} id="app__mobileMenu">
        <img src={ICO_MENU} alt=""/>
      </Button>
    );
  }

  return (
    <div className="App" onClick={closeMobileMenu} onTouchMove={closeMobileMenu}>
      <header className="app__header" id="app__header">
        <Accordion onSelect={(ek, ev) => setIsMobileMenuOpen(ek !== null)}>
          <Container className="d-flex align-items-center h-100">
            <div className="clickable app__logo" onClick={() => navigate('/')}><img src={RUBY_PAY} alt=""/></div>
            <div className="flex-fill" />
            <span className="app__header__menuTitle d-lg-block d-none" onClick={() => navigate('/')}>{intl.formatMessage({id: "header.home"})}</span>
            <span className="app__header__menuTitle d-lg-block d-none" onClick={() => handleHomeSectionClick('app__services')}>{intl.formatMessage({id: "header.services"})}</span>
            <span className="app__header__menuTitle d-lg-block d-none" onClick={() => handleContactClick()}>{intl.formatMessage({id: "header.contact"})}</span>
            <span className="app__header__menuTitle highlight d-md-block d-none" onClick={() => navigate('/enroll')}>{intl.formatMessage({id: "header.enroll"})}</span>
            <Dropdown className="app__changeLangDropdown">
              <Dropdown.Toggle variant="secondary" className="app__changeLangBtn">
                {localeLabel}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  locales.filter((l) => l.value !== locale).map((l) => (
                    <Dropdown.Item key={l.value} onClick={() => 
                      {
                        setLocale(l.value);
                        setLocaleLabel(l.label);
                      }}>
                      { l.label }
                    </Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
            <MobileMenuBtn/>

          </Container>
          <Accordion.Collapse eventKey="0">
            <Container>
              <span className="app__header__menuTitle" onClick={() => navigate('/')}>{intl.formatMessage({id: "header.home"})}</span>
              <span className="app__header__menuTitle" onClick={() => handleHomeSectionClick('app__services')}>{intl.formatMessage({id: "header.services"})}</span>
              <span className="app__header__menuTitle" onClick={() => handleContactClick()}>{intl.formatMessage({id: "header.contact"})}</span>
              <span className="app__header__menuTitle d-md-none" onClick={() => navigate('/enroll')}>{intl.formatMessage({id: "header.enroll"})}</span>
            </Container>
          </Accordion.Collapse>
        </Accordion>

      </header>
      <div className="body">
        <Outlet/>
        <footer className="footer__wrapper">
          <Container>
            <Row>
              <Col md={6} lg={3} className="align-self-center">
                <img
                  className="clickable"
                  onClick={() => {
                    navigate('/');
                    toTop();
                  }}
                  src={RUBY_PAY}
                  alt=""
                />
              </Col>
              <Col md={6} lg={3} className="footer__column">
                <h4>{intl.formatMessage({id: "header.home"})}</h4>
                <p className="clickable" onClick={() => handleHomeSectionClick('app__aboutus')}>{intl.formatMessage({id: "section1.aboutUs"})}</p>
                <p className="clickable" onClick={() => handleHomeSectionClick('app__wcu')}>{intl.formatMessage({id: "section2.whyChoose"})}</p>
              </Col>
              <Col md={6} lg={3} className="footer__column">
                <h4>{intl.formatMessage({id: "header.services"})}</h4>
                <p className="clickable" onClick={() => handleHomeSectionClick('app__services')}>{intl.formatMessage({id: "section3.products"})}</p>
                <p className="clickable" onClick={() => handleHomeSectionClick('app__hdis')}>{intl.formatMessage({id: "section4.howDoIStart"})}</p>
              </Col>
              <Col md={6} lg={3} className="footer__column">
                <h4>{intl.formatMessage({id: "header.contact"})}</h4>
                {/*<p><img src={ICO_PHONE} alt=""/> +60-xxxxxxxxxxx</p>*/}
                <div className="d-flex">
                  <img src={ICO_LOCATION} alt=""/>
                  <p>Suite B-08, Metropole Sq, Sha Tin District, Hong Kong</p>
                </div>
                <div className="d-flex">
                  <img src={ICO_MAIL} alt=""/>
                  <p>info@ruby365pay.com</p>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="footer__copyright pt-5" id="app__footer">
            {/*<div>*/}
            {/*  <img src={ICO_FACEBOOK} alt="" />*/}
            {/*  <img src={ICO_INSTAGRAM} alt="" />*/}
            {/*  <img src={ICO_TWITTER} alt="" />*/}
            {/*</div>*/}
            <p>Copyright @ 2023 RubyPay | All Right Reserved</p>
          </Container>
        </footer>
      </div>
    </div>
  );
}

export default App;
